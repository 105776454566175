import {PropsWithChildren, useCallback, useEffect, useMemo, useState} from "react";
import {QueryStringContext} from "./QueryStringContext.ts";
import {queryStringSetUrl} from "../utils/queryString.ts";

export function QueryStringProvider(props: Readonly<PropsWithChildren>) {
    const [state, setState] = useState<string>();
    const [calc, setCalc] = useState<number>();
    const [type, setType] = useState<string>();

    const setStateInternal = useCallback((state?: string) => {
        if (state) {
            setState(state)
            window.localStorage.setItem('state', state)
            if (queryStringSetUrl({st: state})) {
                queryStringSetUrl({current: null})
            }
        }
    }, [])

    const setCalcInternal = useCallback((calc?: number) => {
        setCalc(calc)
        if (calc && !isNaN(calc)) {
            window.localStorage.setItem('calc', calc.toString())
        } else {
            window.localStorage.removeItem('calc')
        }
        if (queryStringSetUrl({calc: calc?.toString() ?? null})) {
            queryStringSetUrl({current: null})
        }
    }, [])

    const setTypeInternal = useCallback((type: string) => {
        setType(type)
        if (queryStringSetUrl({type: type})) {
            queryStringSetUrl({current: null})
        }
    }, [])

    const value = useMemo(() => {
        return {
            state,
            calc,
            type,
            setType: setTypeInternal,
            setCalc: setCalcInternal,
            setState: setStateInternal,
        }
    }, [setTypeInternal, setCalcInternal, setStateInternal, state, calc, type])


    useEffect(() => {
        const url = new URL(window.location.href);
        const currentValues: Record<string, string> = {}
        for (const k of url.searchParams.keys()) {
            currentValues[k] = url.searchParams.get(k) ?? ""
        }
        setType(url.searchParams.get("type") ?? undefined);
        setStateInternal(url.searchParams.get('st') ?? window.localStorage.getItem('state') ?? undefined)
        const calcString = url.searchParams.get('calc') ?? window.localStorage.getItem('calc') ?? undefined
        if (calcString !== undefined) {
            const calcNumber = Number(calcString)
            if (!isNaN(calcNumber)) {
                setCalcInternal(calcNumber)
            }
        }
    }, [setCalcInternal, setStateInternal]);

    return <QueryStringContext.Provider value={value}>
        {props.children}
    </QueryStringContext.Provider>
}
