import {PropsWithChildren} from "react";
import axios, {AxiosError, AxiosInstance} from "axios";
import {message} from "antd";
import {Configuration} from "../generated/api";
import {AuthContextProps, useAuth} from "react-oidc-context";
import {ApiContext} from "./ApiContext.tsx";
import {MainApiWithGeoserver} from "./MainApiWithGeoserver.tsx";

function getAxiosInstance(auth: AuthContextProps) {
    const axiosInstance: AxiosInstance & { isRefreshing?: boolean } = axios.create()

    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers.setAuthorization(`Bearer ${auth.user?.access_token}`)
            return config;
        },
        (error: AxiosError) => {
            return Promise.reject(error)
        });

    const failedRequests: {
        resolve: (field: undefined) => void
        reject: (error: Error | null) => void
    }[] = []

    function processQueue(error: Error | null) {
        failedRequests.forEach((prom) => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(undefined);
            }
        });
        failedRequests.length = 0; // empty the array
    }

    axiosInstance.interceptors.response.use(
        res => res,
        error => {
            if (error.response?.status === 401) {
                if (!error.config?.__isRetryRequest) {
                    if (axiosInstance.isRefreshing) {
                        try {
                            return new Promise((resolve, reject) => {
                                failedRequests.push({resolve, reject});
                            }).then(() => {
                                error.config.__isRetryRequest = true
                                return axiosInstance(error.config);
                            })
                        } catch (e) {
                            return e;
                        }
                    }
                    axiosInstance.isRefreshing = true;
                    error.config.__isRetryRequest = true;
                    return new Promise((resolve, reject) => {
                        auth.signinSilent().then(() => {
                            axiosInstance.isRefreshing = false;
                            processQueue(null);
                            resolve(axiosInstance(error.config));
                        }).catch((e) => {
                            processQueue(e);
                            reject(error.response);
                        });
                    });
                } else {
                    window.location.href = "/"
                }
            } else {
                message.error("Error connecting to server, please try again").then()
            }
            return Promise.reject(error)
        }
    )
    return axiosInstance;
}

function getApi(auth: AuthContextProps) {
    const axiosInstance = getAxiosInstance(auth);
    const configuration = new Configuration({})
    return new MainApiWithGeoserver(configuration, "", axiosInstance)
}

export function ApiProvider(props: Readonly<PropsWithChildren>) {
    const auth = useAuth();
    return <ApiContext.Provider value={getApi(auth)}>
        {props.children}
    </ApiContext.Provider>;
}
