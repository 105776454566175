import {Projection} from "ol/proj";
import {Extent} from "ol/extent";
import VectorSource from "ol/source/Vector";
import {Feature, Map as OpenLayersMap, MapBrowserEvent} from "ol";
import {Geometry} from "ol/geom";
import {bbox} from "ol/loadingstrategy";
import {Select} from "ol/interaction";
import {SelectEvent} from "ol/interaction/Select";

let onStartListener: (() => void) | undefined = undefined
let onEndListener: (() => void) | undefined = undefined

export function featureSourceLoaderOnStart(listener: () => void) {
    onStartListener = listener
}

export function featureSourceLoaderOnEnd(listener: () => void) {
    onEndListener = listener
}

let counter = 0;

export function onStart() {
    if (counter == 0) {
        if (onStartListener) onStartListener()
    }
    counter++;
}

export function onEnd() {
    counter--;
    if (counter == 0) {
        if (onEndListener) onEndListener()
    }
}

export function vectorSourceWithLoader(map: OpenLayersMap, loader: (extent: Extent, zoom: number, projection: Projection) => Promise<Feature<Geometry>[]>, selection?: string) {
    const selectionWrapper = {
        selection: selection
    }
    const source = new VectorSource<Feature<Geometry>>({
        loader: (extent, zoom, projection) => {
            onStart()
            loader(extent, zoom, projection).then(features => {
                try {
                    source.addFeatures(features)
                    if (selectionWrapper.selection) {
                        const sel = atob(selectionWrapper.selection)
                        const selectedFeature = features.find(x => x.getId() === sel)
                        selectionWrapper.selection = undefined
                        if (selectedFeature) {
                            map?.getInteractions().getArray().map(x => {
                                if (x instanceof Select) {
                                    x.getFeatures().clear()
                                    x.getFeatures().push(selectedFeature);
                                    x.dispatchEvent(new SelectEvent('select', [selectedFeature], [], new MapBrowserEvent('select', map, new UIEvent('select'))));
                                }
                            })
                        }
                    }
                } finally {
                    onEnd()
                }
            }).catch(e => {
                console.log(e)
                onEnd()
            })
        },
        strategy: bbox
    })
    return source;
}