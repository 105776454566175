/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AdminDashboardDto
 */
export interface AdminDashboardDto {
    /**
     * 
     * @type {number}
     * @memberof AdminDashboardDto
     */
    'spaceLeft'?: number;
    /**
     * 
     * @type {Array<StateWithCalculationsDto>}
     * @memberof AdminDashboardDto
     */
    'states': Array<StateWithCalculationsDto>;
    /**
     * 
     * @type {Array<JobDescDto>}
     * @memberof AdminDashboardDto
     */
    'jobDescriptions': Array<JobDescDto>;
}
/**
 * 
 * @export
 * @interface ApiKeyRequest
 */
export interface ApiKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiKeyRequest
     */
    'key': string;
}
/**
 * 
 * @export
 * @interface BBoxDto
 */
export interface BBoxDto {
    /**
     * 
     * @type {number}
     * @memberof BBoxDto
     */
    'lonMin': number;
    /**
     * 
     * @type {number}
     * @memberof BBoxDto
     */
    'latMin': number;
    /**
     * 
     * @type {number}
     * @memberof BBoxDto
     */
    'lonMax': number;
    /**
     * 
     * @type {number}
     * @memberof BBoxDto
     */
    'latMax': number;
}
/**
 * 
 * @export
 * @interface CalculationDto
 */
export interface CalculationDto {
    /**
     * 
     * @type {number}
     * @memberof CalculationDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CalculationDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationDto
     */
    'dataStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationDto
     */
    'dataEndDate': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationDto
     */
    'mapDate': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationDto
     */
    'geohashes': string;
    /**
     * 
     * @type {boolean}
     * @memberof CalculationDto
     */
    'defaultCalculation': boolean;
}
/**
 * 
 * @export
 * @interface CalculationWithJobsDto
 */
export interface CalculationWithJobsDto {
    /**
     * 
     * @type {number}
     * @memberof CalculationWithJobsDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CalculationWithJobsDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationWithJobsDto
     */
    'dataStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationWithJobsDto
     */
    'dataEndDate': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationWithJobsDto
     */
    'mapDate': string;
    /**
     * 
     * @type {string}
     * @memberof CalculationWithJobsDto
     */
    'geohashes': string;
    /**
     * 
     * @type {Array<JobV2Dto>}
     * @memberof CalculationWithJobsDto
     */
    'jobs': Array<JobV2Dto>;
    /**
     * 
     * @type {boolean}
     * @memberof CalculationWithJobsDto
     */
    'defaultCalculation': boolean;
}
/**
 * 
 * @export
 * @interface CreateCalculationDto
 */
export interface CreateCalculationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCalculationDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalculationDto
     */
    'dataStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalculationDto
     */
    'dataEndDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalculationDto
     */
    'mapDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalculationDto
     */
    'geohashes': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalculationDto
     */
    'state': string;
}
/**
 * 
 * @export
 * @interface DatesResponseDto
 */
export interface DatesResponseDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DatesResponseDto
     */
    'mapDates': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatesResponseDto
     */
    'calcDates': Array<string>;
}
/**
 * 
 * @export
 * @interface DetectorSpansForQualityDto
 */
export interface DetectorSpansForQualityDto {
    /**
     * 
     * @type {number}
     * @memberof DetectorSpansForQualityDto
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectorSpansForQualityDto
     */
    'haversine'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetectorSpansForQualityDto
     */
    'penetration'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DetectorSpansForQualityDto
     */
    'typeTheSame'?: boolean;
}
/**
 * 
 * @export
 * @interface JobDescDto
 */
export interface JobDescDto {
    /**
     * 
     * @type {string}
     * @memberof JobDescDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof JobDescDto
     */
    'shortName': string;
    /**
     * 
     * @type {string}
     * @memberof JobDescDto
     */
    'jobIdTemplate': string;
}
/**
 * 
 * @export
 * @interface JobProgress
 */
export interface JobProgress {
    /**
     * 
     * @type {number}
     * @memberof JobProgress
     */
    'progress': number;
    /**
     * 
     * @type {string}
     * @memberof JobProgress
     */
    'status': JobProgressStatusEnum;
}

export const JobProgressStatusEnum = {
    Unknown: 'UNKNOWN',
    Running: 'RUNNING',
    Done: 'DONE',
    Error: 'ERROR',
    Ready: 'READY',
    NotReady: 'NOT_READY',
    Starting: 'STARTING',
    Queued: 'QUEUED',
    Terminated: 'TERMINATED',
    Outdated: 'OUTDATED'
} as const;

export type JobProgressStatusEnum = typeof JobProgressStatusEnum[keyof typeof JobProgressStatusEnum];

/**
 * 
 * @export
 * @interface JobV2Dto
 */
export interface JobV2Dto {
    /**
     * 
     * @type {string}
     * @memberof JobV2Dto
     */
    'status': JobV2DtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JobV2Dto
     */
    'jobId': string;
    /**
     * 
     * @type {string}
     * @memberof JobV2Dto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof JobV2Dto
     */
    'shortName': string;
    /**
     * 
     * @type {string}
     * @memberof JobV2Dto
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobV2Dto
     */
    'debug'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobV2Dto
     */
    'statusAssigned'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobV2Dto
     */
    'lastRunSeconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobV2Dto
     */
    'layerSize'?: number;
}

export const JobV2DtoStatusEnum = {
    Unknown: 'UNKNOWN',
    Running: 'RUNNING',
    Done: 'DONE',
    Error: 'ERROR',
    Ready: 'READY',
    NotReady: 'NOT_READY',
    Starting: 'STARTING',
    Queued: 'QUEUED',
    Terminated: 'TERMINATED',
    Outdated: 'OUTDATED'
} as const;

export type JobV2DtoStatusEnum = typeof JobV2DtoStatusEnum[keyof typeof JobV2DtoStatusEnum];

/**
 * 
 * @export
 * @interface QualityDto
 */
export interface QualityDto {
    /**
     * 
     * @type {number}
     * @memberof QualityDto
     */
    'detectorCoefficient'?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityDto
     */
    'disbalanceCoefficient'?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityDto
     */
    'distanceCoefficient'?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityDto
     */
    'quality'?: number;
}
/**
 * 
 * @export
 * @interface SearchRequestDto
 */
export interface SearchRequestDto {
    /**
     * 
     * @type {string}
     * @memberof SearchRequestDto
     */
    'search': string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestDto
     */
    'state': string;
}
/**
 * 
 * @export
 * @interface SearchResultDto
 */
export interface SearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof SearchResultDto
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchResultDto
     */
    'withinState': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchResultDto
     */
    'coordinates': boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchResultDto
     */
    'lon': number;
    /**
     * 
     * @type {number}
     * @memberof SearchResultDto
     */
    'lat': number;
    /**
     * 
     * @type {string}
     * @memberof SearchResultDto
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface StartMultipleJobsDto
 */
export interface StartMultipleJobsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof StartMultipleJobsDto
     */
    'shortNames': Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StartMultipleJobsDto
     */
    'context': { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof StartMultipleJobsDto
     */
    'force': boolean;
}
/**
 * 
 * @export
 * @interface StateDto
 */
export interface StateDto {
    /**
     * 
     * @type {string}
     * @memberof StateDto
     */
    'state': string;
    /**
     * 
     * @type {number}
     * @memberof StateDto
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof StateDto
     */
    'lon': number;
}
/**
 * 
 * @export
 * @interface StateWithCalculationsDto
 */
export interface StateWithCalculationsDto {
    /**
     * 
     * @type {string}
     * @memberof StateWithCalculationsDto
     */
    'state': string;
    /**
     * 
     * @type {number}
     * @memberof StateWithCalculationsDto
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof StateWithCalculationsDto
     */
    'lon': number;
    /**
     * 
     * @type {Array<CalculationWithJobsDto>}
     * @memberof StateWithCalculationsDto
     */
    'calculations': Array<CalculationWithJobsDto>;
}

/**
 * MainApi - axios parameter creator
 * @export
 */
export const MainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDashboard: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/adminDashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cleanType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmClean: async (cleanType: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cleanType' is not null or undefined
            assertParamExists('confirmClean', 'cleanType', cleanType)
            const localVarPath = `/api/admin/v1/confirmClean`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cleanType !== undefined) {
                localVarQueryParameter['cleanType'] = cleanType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCalculationDto} createCalculationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalculation: async (createCalculationDto: CreateCalculationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCalculationDto' is not null or undefined
            assertParamExists('createCalculation', 'createCalculationDto', createCalculationDto)
            const localVarPath = `/api/admin/v1/calculation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCalculationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} geohashes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataBbox: async (geohashes: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'geohashes' is not null or undefined
            assertParamExists('dataBbox', 'geohashes', geohashes)
            const localVarPath = `/api/v1/dataBbox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (geohashes !== undefined) {
                localVarQueryParameter['geohashes'] = geohashes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalculation: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCalculation', 'id', id)
            const localVarPath = `/api/admin/v1/deleteCalculation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} extent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (key: string, extent: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('download', 'key', key)
            // verify required parameter 'extent' is not null or undefined
            assertParamExists('download', 'extent', extent)
            const localVarPath = `/data/api/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (extent !== undefined) {
                localVarQueryParameter['extent'] = extent;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cleanType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCleanObjects: async (cleanType: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cleanType' is not null or undefined
            assertParamExists('getCleanObjects', 'cleanType', cleanType)
            const localVarPath = `/api/admin/v1/cleanObjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cleanType !== undefined) {
                localVarQueryParameter['cleanType'] = cleanType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDates: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/v1/dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<DetectorSpansForQualityDto>} detectorSpansForQualityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuality: async (detectorSpansForQualityDto: Array<DetectorSpansForQualityDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'detectorSpansForQualityDto' is not null or undefined
            assertParamExists('getQuality', 'detectorSpansForQualityDto', detectorSpansForQualityDto)
            const localVarPath = `/api/v1/calculateQuality`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detectorSpansForQualityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} layer 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecord: async (layer: string, query: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'layer' is not null or undefined
            assertParamExists('getRecord', 'layer', layer)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getRecord', 'query', query)
            const localVarPath = `/api/v1/getRecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (layer !== undefined) {
                localVarQueryParameter['layer'] = layer;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStates: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/states`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobProgress: async (requestBody: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('jobProgress', 'requestBody', requestBody)
            const localVarPath = `/api/admin/v1/jobProgress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobStatus: async (jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('jobStatus', 'jobId', jobId)
            const localVarPath = `/api/admin/v1/jobStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalculations: async (state: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('listCalculations', 'state', state)
            const localVarPath = `/api/v1/calculations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} command 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobsByCommand: async (command: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'command' is not null or undefined
            assertParamExists('listJobsByCommand', 'command', command)
            const localVarPath = `/api/admin/v1/listJobsByCommand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (command !== undefined) {
                localVarQueryParameter['command'] = command;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} calcId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeDefault: async (calcId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calcId' is not null or undefined
            assertParamExists('makeDefault', 'calcId', calcId)
            const localVarPath = `/api/admin/v1/makeDefault`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (calcId !== undefined) {
                localVarQueryParameter['calcId'] = calcId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} calcName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeDefaultAll: async (calcName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calcName' is not null or undefined
            assertParamExists('makeDefaultAll', 'calcName', calcName)
            const localVarPath = `/api/admin/v1/makeDefaultAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (calcName !== undefined) {
                localVarQueryParameter['calcName'] = calcName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ogcFeatures: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data/api/ogcfeatureserver`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ogcFeaturesConformance: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data/api/ogcfeatureserver/conformance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ogcFeaturesData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data/api/ogcfeatureserver/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [bbox] 
         * @param {string} [bboxCrs] 
         * @param {string} [crs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ogcFeaturesItems: async (limit: number, bbox?: string, bboxCrs?: string, crs?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('ogcFeaturesItems', 'limit', limit)
            const localVarPath = `/data/api/ogcfeatureserver/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (bbox !== undefined) {
                localVarQueryParameter['bbox'] = bbox;
            }

            if (bboxCrs !== undefined) {
                localVarQueryParameter['bbox-crs'] = bboxCrs;
            }

            if (crs !== undefined) {
                localVarQueryParameter['crs'] = crs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetJob: async (jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('resetJob', 'jobId', jobId)
            const localVarPath = `/api/admin/v1/resetJob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (searchRequestDto: SearchRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequestDto' is not null or undefined
            assertParamExists('search', 'searchRequestDto', searchRequestDto)
            const localVarPath = `/api/v1/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobIdTemplate 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startJob: async (jobIdTemplate: string, requestBody: { [key: string]: string; }, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobIdTemplate' is not null or undefined
            assertParamExists('startJob', 'jobIdTemplate', jobIdTemplate)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('startJob', 'requestBody', requestBody)
            const localVarPath = `/api/admin/v1/startJob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobIdTemplate !== undefined) {
                localVarQueryParameter['jobIdTemplate'] = jobIdTemplate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} command 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startJobByCommand: async (command: string, requestBody: { [key: string]: string; }, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'command' is not null or undefined
            assertParamExists('startJobByCommand', 'command', command)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('startJobByCommand', 'requestBody', requestBody)
            const localVarPath = `/api/admin/v1/startJobByCommand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (command !== undefined) {
                localVarQueryParameter['command'] = command;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startJobById: async (jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('startJobById', 'jobId', jobId)
            const localVarPath = `/api/admin/v1/startById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<StartMultipleJobsDto>} startMultipleJobsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startMultipleJobs: async (startMultipleJobsDto: Array<StartMultipleJobsDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startMultipleJobsDto' is not null or undefined
            assertParamExists('startMultipleJobs', 'startMultipleJobsDto', startMultipleJobsDto)
            const localVarPath = `/api/admin/v1/startMultiple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startMultipleJobsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateJob: async (jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('terminateJob', 'jobId', jobId)
            const localVarPath = `/api/admin/v1/terminateJob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiKeyRequest} apiKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateApiKey: async (apiKeyRequest: ApiKeyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKeyRequest' is not null or undefined
            assertParamExists('validateApiKey', 'apiKeyRequest', apiKeyRequest)
            const localVarPath = `/data/api/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MainApi - functional programming interface
 * @export
 */
export const MainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDashboard(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDashboardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDashboard(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.adminDashboard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cleanType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmClean(cleanType: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmClean(cleanType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.confirmClean']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateCalculationDto} createCalculationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCalculation(createCalculationDto: CreateCalculationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCalculation(createCalculationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.createCalculation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} geohashes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataBbox(geohashes: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BBoxDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataBbox(geohashes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.dataBbox']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalculation(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalculation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.deleteCalculation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} key 
         * @param {string} extent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(key: string, extent: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download(key, extent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.download']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cleanType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCleanObjects(cleanType: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCleanObjects(cleanType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.getCleanObjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDates(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDates(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.getDates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<DetectorSpansForQualityDto>} detectorSpansForQualityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuality(detectorSpansForQualityDto: Array<DetectorSpansForQualityDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuality(detectorSpansForQualityDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.getQuality']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} layer 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecord(layer: string, query: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: object; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecord(layer, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.getRecord']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStates(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStates(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.getStates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobProgress(requestBody: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: JobProgress; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobProgress(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.jobProgress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobStatus(jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobStatus(jobId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.jobStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCalculations(state: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalculationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCalculations(state, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.listCalculations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} command 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listJobsByCommand(command: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobV2Dto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listJobsByCommand(command, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.listJobsByCommand']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} calcId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeDefault(calcId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeDefault(calcId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.makeDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} calcName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeDefaultAll(calcName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeDefaultAll(calcName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.makeDefaultAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ogcFeatures(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ogcFeatures(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.ogcFeatures']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ogcFeaturesConformance(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ogcFeaturesConformance(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.ogcFeaturesConformance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ogcFeaturesData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ogcFeaturesData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.ogcFeaturesData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [bbox] 
         * @param {string} [bboxCrs] 
         * @param {string} [crs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ogcFeaturesItems(limit: number, bbox?: string, bboxCrs?: string, crs?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ogcFeaturesItems(limit, bbox, bboxCrs, crs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.ogcFeaturesItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetJob(jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetJob(jobId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.resetJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(searchRequestDto: SearchRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(searchRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.search']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} jobIdTemplate 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startJob(jobIdTemplate: string, requestBody: { [key: string]: string; }, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startJob(jobIdTemplate, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.startJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} command 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startJobByCommand(command: string, requestBody: { [key: string]: string; }, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startJobByCommand(command, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.startJobByCommand']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startJobById(jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startJobById(jobId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.startJobById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<StartMultipleJobsDto>} startMultipleJobsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startMultipleJobs(startMultipleJobsDto: Array<StartMultipleJobsDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startMultipleJobs(startMultipleJobsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.startMultipleJobs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminateJob(jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminateJob(jobId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.terminateJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiKeyRequest} apiKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateApiKey(apiKeyRequest: ApiKeyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateApiKey(apiKeyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MainApi.validateApiKey']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MainApi - factory interface
 * @export
 */
export const MainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MainApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDashboard(options?: any): AxiosPromise<AdminDashboardDto> {
            return localVarFp.adminDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cleanType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmClean(cleanType: string, options?: any): AxiosPromise<void> {
            return localVarFp.confirmClean(cleanType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCalculationDto} createCalculationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalculation(createCalculationDto: CreateCalculationDto, options?: any): AxiosPromise<void> {
            return localVarFp.createCalculation(createCalculationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} geohashes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataBbox(geohashes: string, options?: any): AxiosPromise<BBoxDto> {
            return localVarFp.dataBbox(geohashes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalculation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCalculation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {string} extent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(key: string, extent: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.download(key, extent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cleanType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCleanObjects(cleanType: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getCleanObjects(cleanType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDates(options?: any): AxiosPromise<DatesResponseDto> {
            return localVarFp.getDates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<DetectorSpansForQualityDto>} detectorSpansForQualityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuality(detectorSpansForQualityDto: Array<DetectorSpansForQualityDto>, options?: any): AxiosPromise<QualityDto> {
            return localVarFp.getQuality(detectorSpansForQualityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} layer 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecord(layer: string, query: string, options?: any): AxiosPromise<Array<{ [key: string]: object; }>> {
            return localVarFp.getRecord(layer, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStates(options?: any): AxiosPromise<Array<StateDto>> {
            return localVarFp.getStates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobProgress(requestBody: Array<string>, options?: any): AxiosPromise<{ [key: string]: JobProgress; }> {
            return localVarFp.jobProgress(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobStatus(jobId: string, options?: any): AxiosPromise<string> {
            return localVarFp.jobStatus(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalculations(state: string, options?: any): AxiosPromise<Array<CalculationDto>> {
            return localVarFp.listCalculations(state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} command 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobsByCommand(command: string, options?: any): AxiosPromise<Array<JobV2Dto>> {
            return localVarFp.listJobsByCommand(command, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} calcId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeDefault(calcId: number, options?: any): AxiosPromise<void> {
            return localVarFp.makeDefault(calcId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} calcName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeDefaultAll(calcName: string, options?: any): AxiosPromise<void> {
            return localVarFp.makeDefaultAll(calcName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ogcFeatures(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.ogcFeatures(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ogcFeaturesConformance(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.ogcFeaturesConformance(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ogcFeaturesData(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.ogcFeaturesData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {string} [bbox] 
         * @param {string} [bboxCrs] 
         * @param {string} [crs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ogcFeaturesItems(limit: number, bbox?: string, bboxCrs?: string, crs?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.ogcFeaturesItems(limit, bbox, bboxCrs, crs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetJob(jobId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resetJob(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchRequestDto} searchRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(searchRequestDto: SearchRequestDto, options?: any): AxiosPromise<Array<SearchResultDto>> {
            return localVarFp.search(searchRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobIdTemplate 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startJob(jobIdTemplate: string, requestBody: { [key: string]: string; }, options?: any): AxiosPromise<string> {
            return localVarFp.startJob(jobIdTemplate, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} command 
         * @param {{ [key: string]: string; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startJobByCommand(command: string, requestBody: { [key: string]: string; }, options?: any): AxiosPromise<string> {
            return localVarFp.startJobByCommand(command, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startJobById(jobId: string, options?: any): AxiosPromise<void> {
            return localVarFp.startJobById(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<StartMultipleJobsDto>} startMultipleJobsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startMultipleJobs(startMultipleJobsDto: Array<StartMultipleJobsDto>, options?: any): AxiosPromise<void> {
            return localVarFp.startMultipleJobs(startMultipleJobsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateJob(jobId: string, options?: any): AxiosPromise<void> {
            return localVarFp.terminateJob(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiKeyRequest} apiKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateApiKey(apiKeyRequest: ApiKeyRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.validateApiKey(apiKeyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MainApi - object-oriented interface
 * @export
 * @class MainApi
 * @extends {BaseAPI}
 */
export class MainApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public adminDashboard(options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).adminDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cleanType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public confirmClean(cleanType: string, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).confirmClean(cleanType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCalculationDto} createCalculationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public createCalculation(createCalculationDto: CreateCalculationDto, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).createCalculation(createCalculationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} geohashes 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public dataBbox(geohashes: string, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).dataBbox(geohashes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public deleteCalculation(id: number, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).deleteCalculation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} key 
     * @param {string} extent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public download(key: string, extent: string, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).download(key, extent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cleanType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getCleanObjects(cleanType: string, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).getCleanObjects(cleanType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getDates(options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).getDates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<DetectorSpansForQualityDto>} detectorSpansForQualityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getQuality(detectorSpansForQualityDto: Array<DetectorSpansForQualityDto>, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).getQuality(detectorSpansForQualityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} layer 
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getRecord(layer: string, query: string, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).getRecord(layer, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getStates(options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).getStates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public jobProgress(requestBody: Array<string>, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).jobProgress(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public jobStatus(jobId: string, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).jobStatus(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} state 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listCalculations(state: string, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).listCalculations(state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} command 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public listJobsByCommand(command: string, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).listJobsByCommand(command, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} calcId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public makeDefault(calcId: number, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).makeDefault(calcId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} calcName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public makeDefaultAll(calcName: string, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).makeDefaultAll(calcName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public ogcFeatures(options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).ogcFeatures(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public ogcFeaturesConformance(options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).ogcFeaturesConformance(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public ogcFeaturesData(options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).ogcFeaturesData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {string} [bbox] 
     * @param {string} [bboxCrs] 
     * @param {string} [crs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public ogcFeaturesItems(limit: number, bbox?: string, bboxCrs?: string, crs?: string, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).ogcFeaturesItems(limit, bbox, bboxCrs, crs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public resetJob(jobId: string, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).resetJob(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchRequestDto} searchRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public search(searchRequestDto: SearchRequestDto, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).search(searchRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} jobIdTemplate 
     * @param {{ [key: string]: string; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public startJob(jobIdTemplate: string, requestBody: { [key: string]: string; }, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).startJob(jobIdTemplate, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} command 
     * @param {{ [key: string]: string; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public startJobByCommand(command: string, requestBody: { [key: string]: string; }, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).startJobByCommand(command, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public startJobById(jobId: string, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).startJobById(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<StartMultipleJobsDto>} startMultipleJobsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public startMultipleJobs(startMultipleJobsDto: Array<StartMultipleJobsDto>, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).startMultipleJobs(startMultipleJobsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public terminateJob(jobId: string, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).terminateJob(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiKeyRequest} apiKeyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public validateApiKey(apiKeyRequest: ApiKeyRequest, options?: RawAxiosRequestConfig) {
        return MainApiFp(this.configuration).validateApiKey(apiKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



