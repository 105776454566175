import {createContext} from "react";

interface QueryString {
    state?: string
    calc?: number
    type?: string
    setState: (state: string) => void
    setCalc: (calc?: number) => void
    setType: (type: string) => void
}

export const QueryStringContext = createContext<QueryString>({
    setCalc: () => {
    },
    setState: () => {
    },
    setType: () => {
    },
})