export function queryStringGetValue(key: string) {
    return new URL(window.location.href).searchParams.get(key) ?? undefined
}

export function queryStringSetUrl(values: Record<string, string | null>) {
    const entries = [...new URLSearchParams(window.location.search).entries()]
    let anyChanged = false
    for (const k of Object.entries(values)) {
        const oldVal = entries.find(x => x[0] === k[0])
        if (oldVal?.[1] !== (k[1] ?? null)) {
            anyChanged = true
            if (oldVal) {
                oldVal[1] = k[1] ?? ""
            } else {
                entries.push([k[0], k[1] ?? ""])
            }
        }
    }
    if (anyChanged) {
        const url = window.location.pathname + (entries.length > 0 ? "?" : "") + entries.filter(v => !!v[1]).map(v => v[0] + "=" + v[1]).join("&")
        window.history.replaceState({}, '', url);
    }
    return anyChanged
}

export function queryStringSetValue(key: string, value: string | null) {
    queryStringSetUrl({
        [key]: value
    })
}
