import {MainApi} from "../generated/api";
import {Projection, toLonLat} from "ol/proj";
import {Extent} from "ol/extent";
import {onEnd, onStart} from "../map/featureSourceLoader.ts";
import {GeoJSON} from "ol/format";
import {message} from "antd";
import {Coordinate} from "ol/coordinate";
import {Tile} from "ol";
import ImageTile from "ol/ImageTile";
import {TileWMS} from "ol/source";
import {AxiosResponse, ResponseType} from "axios";

export interface OGCFilter {
    field: string
    op: string
    value: string | number | string[]
}

export interface GeoServerQueryParams {
    showLoader?: boolean
    filter?: string
    propertyNames?: string[]
    projection?: Projection
    startIndex?: number
    maxFeatures?: number
    bbox?: {
        name: string
        extent: Extent
    }
}

export function convertFiltersToString(filters: OGCFilter[]): string[] {
    return filters.map(x => {
        if (x.op !== 'in') {
            return x.field + x.op + (typeof (x.value) === 'string' ? "'" + x.value + "'" : x.value)
        } else {
            if (!Array.isArray(x.value)) throw new Error("Invalid arg")
            return x.field + " in (" + x.value.map(z => "'" + z + "'").join(",") + ")"
        }
    })
}

export class MainApiWithGeoserver extends MainApi {
    private WORKSPACE = "main"
    private LAYER_PREFIX = "layer_"
    private filterOps: { [key: string]: string } = {
        '=': 'ogc:PropertyIsEqualTo',
        '>': 'ogc:PropertyIsGreaterThan',
        '>=': 'ogc:PropertyIsGreaterThanOrEqualTo',
        '<': 'ogc:PropertyIsLessThan',
        '<=': 'ogc:PropertyIsLessThanOrEqualTo',
    }

    public async retrieve(layerName: string, params: GeoServerQueryParams) {
        return this.retrieveFeaturesInternal(this.LAYER_PREFIX + layerName, params);
    }

    public async retrieveByFeatureId(featureId: string) {
        const layerName = featureId.split('.')[0]
        return this.retrieveFeaturesInternal(layerName, {}, '&featureID=' + featureId)
    }

    public async retrieveByRadius(layer: string, field: string, c: Coordinate, r: number) {
        return this.retrieveFeaturesInternal(this.LAYER_PREFIX + layer, {filter: 'DWITHIN(' + field + ',Point(' + c[0].toFixed(6) + ' ' + c[1].toFixed(6) + '),' + r + ',meters)'})
    }

    public async retrieveCount(layerName: string, params: GeoServerQueryParams) {
        const response = await this.retrieveInternal(this.LAYER_PREFIX + layerName, params, '1.1.0', '&resultType=hits')
        const text = response.data
        const parser = new DOMParser();
        const xml = parser.parseFromString(text, "application/xml");
        return Number(xml.evaluate("/*/@numberOfFeatures", xml, null, 2).stringValue)
    }

    // accepts N thresholds returns N+1 values
    public async aggregateByThresholds(layer: string, field: string, filters: OGCFilter[], thresholds: number[]): Promise<number[]> {
        const results = await this.sendAggregateQueryInternal(this.LAYER_PREFIX + layer, this.aggregateByFormulaQuery(this.LAYER_PREFIX + layer, filters, this.getCategorizeFunction(field, thresholds)))
        const ret = []
        for (let i = 0; i < thresholds.length + 1; i++) {
            ret.push(results[i] ?? 0)
        }
        return ret;
    }

    public async aggregateByField(layer: string, field: string, filters: OGCFilter[]): Promise<Record<string, number>> {
        return this.sendAggregateQueryInternal(this.LAYER_PREFIX + layer, this.aggregateByFieldQuery(this.LAYER_PREFIX + layer, filters, field))
    }

    public createTileWMS(layer: string, filters?: string, style?: string, env?: Record<string, string | number>) {
        function tileLoadFunction(this: MainApiWithGeoserver, tile: Tile, src: string) {
            if (tile instanceof ImageTile) {
                this.axios.get(src, {
                    responseType: 'arraybuffer'
                }).then(response => {
                    const arrayBufferView = new Uint8Array(response.data);
                    const blob = new Blob([arrayBufferView], {type: 'image/png'});
                    const urlCreator = window.URL || window.webkitURL;
                    const imageUrl = urlCreator.createObjectURL(blob);
                    (tile.getImage() as HTMLImageElement).src = imageUrl;
                    setTimeout(() => urlCreator.revokeObjectURL(imageUrl), 5000);
                })
            }
        }

        return new TileWMS({
            url: '/geoserver/' + this.WORKSPACE + '/wms',
            tileLoadFunction: tileLoadFunction.bind(this),
            params: {
                LAYERS: this.WORKSPACE + ':' + this.LAYER_PREFIX + layer,
                TILED: true,
                ...filters ? {
                    CQL_FILTER: filters,
                } : {},
                ...style ? {
                    STYLES: style,
                } : {},
                ...env ? {
                    env: Object.entries(env).map(x => x.join(":")).join(";"),
                } : {},
            },
            serverType: 'geoserver',
            transition: 0
        })
    }

    public getFeatureLayer(featureId: string): string {
        const layerInternalName = featureId.split('.')[0];
        if (!layerInternalName.startsWith(this.LAYER_PREFIX)) throw new Error("Incorrect feature id")
        return layerInternalName.substring(this.LAYER_PREFIX.length)
    }

    private async retrieveInternal(internalLayerName: string, params: GeoServerQueryParams, version: string, additional?: string, responseType?: ResponseType) {
        let propertyNamesFilter = '';
        if (params.propertyNames) {
            propertyNamesFilter = '&propertyName=' + params.propertyNames.map(x => x.toLowerCase()).join(',')
        }
        let filterPart = ''
        if (params.filter) {
            filterPart = '&CQL_FILTER=' + encodeURIComponent(params.filter)
        }
        if (params.bbox?.extent.findIndex(x => isNaN(x)) === -1) {
            if (!filterPart) filterPart = "&CQL_FILTER="
            else filterPart = filterPart + encodeURIComponent(' and ')
            filterPart = filterPart + encodeURIComponent('BBOX(' + params.bbox.name + ',' + toLonLat(params.bbox.extent.slice(0, 2)).join(',') + ',' + toLonLat(params.bbox.extent.slice(2, 4)).join(',') + ')')
        }
        let startIndexPart = ''
        if (params.startIndex) {
            startIndexPart = '&startIndex=' + params.startIndex
        }
        let maxFeaturesPart = ''
        if (params.maxFeatures) {
            maxFeaturesPart = '&maxFeatures=' + params.maxFeatures
        }
        return this.axios.get('/geoserver/' + this.WORKSPACE + '/ows?service=WFS&version=' + version + '&request=GetFeature&typeName=' + this.WORKSPACE + '%3A' + internalLayerName + '&outputFormat=application%2Fjson' + filterPart + propertyNamesFilter + startIndexPart + maxFeaturesPart + (additional ?? ''), {
            responseType: responseType
        })
    }

    private async retrieveFeaturesInternal(internalLayerName: string, params: GeoServerQueryParams, additional?: string) {
        if (params.showLoader) onStart()
        let response: AxiosResponse
        try {
            response = await this.retrieveInternal(internalLayerName, params, '1.0.0', additional, 'arraybuffer')
        } finally {
            if (params.showLoader) onEnd()
        }
        const buf = response.data
        const textDecoder = new TextDecoder("utf-8");
        const txt = textDecoder.decode(buf)
        try {
            return new GeoJSON().readFeatures(txt, {
                featureProjection: params.projection ?? "EPSG:3857",
                dataProjection: "EPSG:4326"
            })
        } catch {
            if (txt.includes("Feature type " + this.WORKSPACE + ":" + internalLayerName + " unknown")) {
                message.destroy()
                message.warning("Layer is not yet calculated", 1);
            } else if (response.status === 401) {
                window.location.href = "/"
            } else {
                message.error("Error loading objects from GeoServer")
            }
            return []
        }
    }

    private aggregateExecuteQuery(input: object, service: string, fieldName: string) {
        return {
            "wps:Execute": {
                "@version": "1.0.0",
                "@service": "WPS",
                "@xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
                "@xmlns": "http://www.opengis.net/wps/1.0.0",
                "@xmlns:wfs": "http://www.opengis.net/wfs",
                "@xmlns:wps": "http://www.opengis.net/wps/1.0.0",
                "@xmlns:ows": "http://www.opengis.net/ows/1.1",
                "@xmlns:gml": "http://www.opengis.net/gml",
                "@xmlns:ogc": "http://www.opengis.net/ogc",
                "@xmlns:wcs": "http://www.opengis.net/wcs/1.1.1",
                "@xmlns:xlink": "http://www.w3.org/1999/xlink",
                "ows:Identifier": "gs:Aggregate",
                "wps:DataInputs": {
                    "wps:Input": [{
                        "ows:Identifier": "features",
                        "wps:Reference": {
                            "@mimeType": "text/xml; subtype=wfs-collection/1.0",
                            "@xlink:href": "http://geoserver/" + service,
                            "@method": "POST",
                            "wps:Body": input
                        }
                    }, {
                        "ows:Identifier": "aggregationAttribute",
                        "wps:Data": {"wps:LiteralData": fieldName}
                    }, {
                        "ows:Identifier": "function",
                        "wps:Data": {"wps:LiteralData": "Count"}
                    }, {
                        "ows:Identifier": "singlePass",
                        "wps:Data": {"wps:LiteralData": "True"}
                    }, {
                        "ows:Identifier": "groupByAttributes",
                        "wps:Data": {"wps:LiteralData": fieldName}
                    }]
                },
                "wps:ResponseForm": {
                    "wps:RawDataOutput": {
                        "@mimeType": "application/json",
                        "ows:Identifier": "result"
                    }
                }
            }
        }
    }

    private getOGCFilterSingle(filter: OGCFilter) {
        if (filter.op === 'in') {
            if (!Array.isArray(filter.value)) {
                throw new Error("invalid arg")
            }
            return {
                "ogc:PropertyIsEqualTo": {
                    "ogc:Function": {
                        "@name": "in",
                        "ogc:PropertyName": filter.field,
                        "ogc:Literal": filter.value
                    },
                    "ogc:Literal": "true"
                }
            }
        } else
            return Object.fromEntries([[this.filterOps[filter.op], {
                "ogc:PropertyName": filter.field,
                "ogc:Literal": filter.value.toString()
            }]])
    }

    private getOGCFilterContent(filters: OGCFilter[]): object {
        if (filters.length === 1) {
            return this.getOGCFilterSingle(filters[0])
        } else {
            const f1 = this.getOGCFilterSingle(filters[0])
            const f2 = this.getOGCFilterContent(filters.slice(1))
            // hacky way, since we are processing arrays in another way
            if (Object.entries(f1)[0][0] !== Object.entries(f2)[0][0]) {
                return {
                    "ogc:And": {...f1, ...f2}
                }
            } else {
                return {
                    "ogc:And": Object.fromEntries([[
                        Object.entries(f1)[0][0], [
                            Object.entries(f1)[0][1],
                            Object.entries(f2)[0][1]
                        ]
                    ]])
                }
            }
        }
    }

    private getOGCFilter(filters: OGCFilter[]) {
        if (filters.length === 0) return []
        return this.getOGCFilterContent(filters)
    }

    private getFeatureQuery(layerName: string, filters: OGCFilter[]) {
        return {
            "wfs:GetFeature": {
                "@service": "WFS",
                "@version": "1.0.0",
                "@outputFormat": "GML2",
                ["@xmlns:" + this.WORKSPACE]: "http://" + this.WORKSPACE,
                "wfs:Query": {
                    "@typeName": layerName,
                    "ogc:Filter": this.getOGCFilter(filters)
                }
            }
        }
    }

    private aggregateByFormulaQuery(layerName: string, filters: OGCFilter[], groupingFormula: string) {
        return this.aggregateExecuteQuery({
            "wps:Execute": {
                "@version": "1.0.0",
                "@service": "WPS",
                "ows:Identifier": "gs:Transform",
                "wps:DataInputs": {
                    "wps:Input": [
                        {
                            "ows:Identifier": "features",
                            "wps:Reference": {
                                "@mimeType": "text/xml; subtype=wfs-collection/1.0",
                                "@xlink:href": "http://geoserver/wfs",
                                "@method": "POST",
                                "wps:Body": this.getFeatureQuery(layerName, filters)
                            }
                        },
                        {
                            "ows:Identifier": "transform",
                            "wps:Data": {
                                "wps:LiteralData": "d=" + groupingFormula
                            }
                        }
                    ]
                },
                "wps:ResponseForm": {
                    "wps:RawDataOutput": {
                        "@mimeType": "text/xml; subtype=wfs-collection/1.0",
                        "ows:Identifier": "result"
                    }
                }
            }
        }, "wps", "d")
    }

    private aggregateByFieldQuery(layerName: string, filters: OGCFilter[], field: string) {
        return this.aggregateExecuteQuery(this.getFeatureQuery(layerName, filters), "wfs", field)
    }

    private async sendWPSQuery(body: string) {
        return this.axios.post('/geoserver/ows', body, {
            headers: {"Content-Type": "text/plain"}
        })
    }

    private getCategorizeFunction(field: string, thresholds: number[]) {
        let ret = "Categorize(" + field + ",0";
        for (let i = 0; i < thresholds.length; i++) {
            ret += "," + thresholds[i] + "," + (i + 1)
        }
        ret += ")"
        return ret;
    }

    // really simple implementation and lacks of many things and checks
    private serializeObj(name: string, obj: object | string | object[] | string[] | null): string {
        if (obj === null || typeof (obj) === 'object' && !Array.isArray(obj) && Object.entries(obj).length === 0) {
            return "<" + name + "/>"
        }
        if (typeof (obj) === 'string') {
            return "<" + name + ">" + obj + "</" + name + ">"
        } else if (Array.isArray(obj)) {
            let ret = "";
            for (const o of obj) {
                ret += this.serializeObj(name, o)
            }
            return ret;
        } else { // object
            return "<" +
                [name, ...Object.entries(obj).filter(x => x[0].startsWith("@")).map(x => x[0].substring(1) + '="' + x[1] + '"')].join(' ') +
                '>' +
                [...Object.entries(obj).filter(x => !x[0].startsWith("@")).map(x => this.serializeObj(x[0], x[1]))].join('') +
                "</" + name + ">"
        }
    }

    private convertToXml(obj: object) {
        const entries = Object.entries(obj)
        if (entries.length > 1) {
            throw new Error("Too many fields in root object")
        }
        return '<?xml version="1.0" encoding="UTF-8"?>' + this.serializeObj(entries[0][0], entries[0][1]);
    }

    private async sendAggregateQueryInternal(layerName: string, req: object): Promise<Record<string | number, number>> {
        const xml = this.convertToXml(req)
        const response = await this.sendWPSQuery(xml)
        const responseData = response.data
        if (typeof responseData === 'string') {
            if (responseData.includes("Could not locate {http://" + this.WORKSPACE + "}" + layerName + " in catalog.")
                || responseData.includes("Could not locate {" + this.WORKSPACE + "}" + layerName + " in catalog.")) {
                message.destroy()
                message.warning("Layer is not yet calculated", 1);
            } else {
                message.error("Error running query on GeoServer")
            }
            return {}
        } else {
            return Object.fromEntries(responseData['AggregationResults'])
        }
    }
}