import {lazy, StrictMode} from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import {ApiProvider} from "./api/ApiProvider.tsx";
import {UserManager, WebStorageStateStore} from "oidc-client-ts";
import {QueryStringProvider} from "./queryString/QueryStringProvider.tsx";
import {QueryStringAwareAuthProvider} from "./queryString/QueryStringAwareAuthProvider.tsx";
import {init as sentryInit} from "@sentry/react";

// This way we push all the "main" code into separate chunk adding a bit more "security"
// So the application code won't be downloaded to browser unless you authenticate
// At the same time the link to the code anyway will be there
export const AuthZone = lazy(() => import('./AuthZone'))

if (import.meta.env.VITE_SENTRY_DSN) {
    sentryInit({
        dsn: import.meta.env.VITE_SENTRY_DSN
    })
}

const userManager = new UserManager({
    authority: import.meta.env.VITE_USER_MANAGER_AUTHORITY,
    client_id: import.meta.env.VITE_USER_MANAGER_CLIENT_ID,
    redirect_uri: import.meta.env.VITE_REDIRECT_URL,
    userStore: new WebStorageStateStore({store: window.localStorage}),
})

ReactDOM.createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <QueryStringProvider>
            <QueryStringAwareAuthProvider userManager={userManager}>
                <ApiProvider>
                    <AuthZone/>
                </ApiProvider>
            </QueryStringAwareAuthProvider>
        </QueryStringProvider>
    </StrictMode>,
)
