import {User, UserManager} from "oidc-client-ts";
import {ReactNode, useEffect, useState} from "react";
import {AuthProvider} from "react-oidc-context";
import {queryStringSetValue} from "../utils/queryString.ts";


function QueryStringUpdater(props: Readonly<{ user?: User }>) {
    useEffect(() => {
        const url = new URL(window.location.href);
        if (props.user?.state) {
            const s = props.user.state as Record<string, string>
            for (const o of [...url.searchParams.keys()]) {
                queryStringSetValue(o, null)
            }
            for (const k in s) {
                if (k !== 'code') {
                    queryStringSetValue(k, s[k])
                }
            }
        }
    }, [props.user]);
    return <></>
}

export function QueryStringAwareAuthProvider(props: Readonly<{ userManager: UserManager, children: ReactNode }>) {
    const [queryStringUpdaterUser, setQueryStringUpdaterUser] = useState<User>();

    // noinspection RequiredAttributes
    return <AuthProvider userManager={props.userManager}
                         onSigninCallback={user => {
                             if (user) {
                                 setQueryStringUpdaterUser(user)
                             }
                         }}
    >
        {props.children}
        <QueryStringUpdater user={queryStringUpdaterUser}/>
    </AuthProvider>
}